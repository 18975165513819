import React, { Component } from 'react'

import Aux from '../../hoc/Aux/Aux'
import Map from '../../components/UI/Map/Map'

import bizPhoto from '../../assets/images/tempimgs/grangeroutside.jpg'
import classes from './Contact.module.css'
import ContactPageForm from '../../components/ContactPageForm/ContactPageForm'

class Contact extends Component {

  render() {
    return (
      <Aux>
        <div className={classes.Content}>
          <div className={classes.ContactHeader}>
            <h1>
              GET IN TOUCH
            </h1>
            <p>GIVE US A CALL AT:</p>
            <p>1-330-239-1077</p>
            <p>M-F 9:00AM-5PM EST.</p>

          </div>
          <div className={classes.ImgContainer}>
            <img src={bizPhoto} alt='granger'></img>
          </div>
        </div>
        <div className={classes.ContentLower}>
          <div className={classes.MapContainer}>
            <Map></Map>
          </div>
          <div className={classes.ContactHeaderLower}>
            {/* CONTACT FORM */}
            <ContactPageForm></ContactPageForm>
          </div>
        </div>      
      </Aux>
    )
  }
}

export default Contact


