import React from 'react';
import classes from './FormResponse.module.css';

const FormResponse = (props) => {
  let resTextHeader = '';
  let resText = '';
  const resClasses = [classes.FormResponse];

  if (props.success)  {
    resTextHeader = 'MESSAGE SENT'; 
    resText = 'THANK YOU FOR YOUR INTEREST';
    resClasses.push(classes.Success);
  } else {
    resTextHeader = 'MESSAGE FAILED'
    resText = 'THERE WAS A PROPBLEM WITH YOUR REQUEST. PLEASE TRY AGAIN';
    resClasses.push(classes.Failed);
  }

  return(
    <div className={resClasses.join(' ')}>
      <h4>{resTextHeader}</h4>
      <p>{resText}</p>
    </div>
  )
};

export default FormResponse;