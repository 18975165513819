import React, { Component } from 'react';

import { withRouter } from 'react-router';

import Input from '../Input/Input';
import classes from './SearchBar.module.css';


class SearchBar extends Component {
  state = {
    searchForm: {
      elementType: 'input',
      elementConfig: {
        type: 'search',
        name: 'partsearch',
        placeholder: 'Search by Part Number',
        minLength: 4
      },
      value: '',
      touched: false,
    },
  }

  inputChangedHandler = (event) => {
    let updatedSearchForm = {...this.state.searchForm}
    const updatedFormElement = { ...updatedSearchForm};
    updatedFormElement.value = event.target.value;
    updatedSearchForm.touched = true;
    updatedSearchForm = updatedFormElement;
    this.setState({
      searchForm: updatedSearchForm
    })
  }
  inputResetHandler = () => {
 
  };
  searchHandler = (event) => {
    event.preventDefault(this.props.clicked);
    
    this.setState({loading: true})
    
    // remove all non alphanumeric characters and spaces
    let searchTerm = this.state.searchForm.value;
    searchTerm = searchTerm.replace(/[\W_]+/g, '');
    searchTerm = encodeURIComponent(searchTerm);

    
    this.props.history.push({
      pathname: '/parts/',
      //search: this.state.searchForm.value,
      state: {
        loading: true,
        query: searchTerm,
      }
    })
    let updatedSearchForm = {...this.state.searchForm}
    const updatedFormElement = { ...updatedSearchForm};
    updatedFormElement.value = '';
    updatedSearchForm.touched = true;
    updatedSearchForm = updatedFormElement;
    this.setState({
      searchForm: updatedSearchForm
    })   
  
  }


  render(){
    const formElementArray = [];
    formElementArray.push({
        config:this.state.searchForm
    })


    return(
      <form onSubmit={this.searchHandler}>
        <div className={classes.SearchWrap}>
        <svg width="18" height="18" viewBox="0 0 18 18" version="1.1" id="svg4" className={classes.SearchIcon}>
          <path fill="#ccc"
          d="M 15.879,18 10.33875,12.45975 C 9.29775,13.11525 8.07075,13.5 6.75,13.5 3.02175,13.5 0,10.47825 0,6.75 0,3.02175 3.02175,0 6.75,0 c 3.72825,0 6.75,3.02175 6.75,6.75 0,1.32075 -0.3855,2.5485 -1.04025,3.58875 L 18,15.879 Z M 6.75,12 C 9.64425,12 12,9.645 12,6.75 12,3.855 9.64425,1.5 6.75,1.5 3.85575,1.5 1.5,3.855 1.5,6.75 1.5,9.645 3.85575,12 6.75,12 Z"
          id="path2"/>
        </svg>
        <Input 
          elementType='search'
          value={this.state.searchForm.value}
          elementConfig={this.state.searchForm.elementConfig}
          changed={(event) => this.inputChangedHandler(event)}
          touched={this.state.searchForm.touched}
        />
        </div>
    </form>
    );
  };
}

export default withRouter(SearchBar);
