import React from 'react';

import Carousel from '../../components/UI/Carousel/Carousel'
import Aux from '../../hoc/Aux/Aux';

import classes from './Home.module.css';
import IMG8329 from '../../assets/images/tempimgs/IMG_8329_cropped3.jpg';

const Home = () => (
  <Aux>
    <Carousel/>
    <div className={classes.Content}>
      <div className={classes.ContentHeader}>
        <h1>GRANGER TRACTOR &amp; PARTS, INC.</h1>
      </div>
      <div className={classes.BioContainer}>
        <h2 className={classes.ContentHeader}>NEW. USED. REBUILT.</h2>
        <p>
          Specializing in Caterpillar&reg; construction equipment, Granger Tractor &amp; 
          Parts purchases and dismantles used equipment, and sells used parts and rebuilt components.
        </p>
        <p>Search our inventory for a great selection of Used Parts and New Surplus Genuine CATERPILLAR&reg; parts.</p>
      </div>
      <div className={classes.ImgContainer}>
        <img src={IMG8329} alt="" className={classes.HomepageImg}></img>
      </div>
      <div className={classes.BioContainerLower}>
      <h2 className={classes.ContentHeader}>FAMILY OWNED AND OPERATED SINCE 1982.</h2>
        <p>
          The Hadam family has been proudly serving the Heavy Equipment Industry for nearly 40 years.
        </p>
        <p>
          Contact us today and see what we can do for you.
        </p>
      </div>
    </div>
  </Aux>
);

export default Home;
