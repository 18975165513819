import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import img1 from '../../../assets/images/tempimgs/IMG_5173_cropped3.jpg'
import img2 from '../../../assets/images/tempimgs/IMG_7777_cropped3.jpg'
import img3 from '../../../assets/images/tempimgs/IMG_8147_cropped3.jpg'
import img4 from '../../../assets/images/tempimgs/IMG_8218_cropped3.jpg'
import img5 from '../../../assets/images/tempimgs/IMG_8399_cropped3.jpg'
import img6 from '../../../assets/images/tempimgs/IMG_8677_cropped3.jpg'

export default () => (
  <Carousel
    autoPlay
    infiniteLoop
    showArrows={false}
    showIndicators={false}
    showThumbs={false}
    showStatus={false}
    interval={5000}
    stopOnHover={false}
    transitionTime={850}
  >
    <div>
      <img src={img1} alt="img"></img>
    </div>
    <div>
      <img src={img2} alt="img"></img>
    </div>
    <div>
      <img src={img3} alt="img"></img>
    </div>
    <div>
      <img src={img4} alt="img"></img>
    </div>
    <div>
      <img src={img5} alt="img"></img>
    </div>    
    <div>
      <img src={img6} alt="img"></img>
    </div>
  </Carousel>
)

