import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import axios from '../../config/axiosConfig';

import classes from './ContactPageForm.module.css';

import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import FormResponse from '../UI/FormResponses/FormResponse/FormResponse';

class ContactPageForm extends Component {
  state = {
    reqForm: {
      name: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Your Name',
          minLength: 3
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        label: 'Name',
        errMessage: "Name must be at least 3 characters long"
      },
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'example@email.com',
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        label: 'Email',
        errMessage: "Invalid email"        
      },
      coName: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Company/Organization',
        },
        value: '',
        validation: {},
        valid: true,
        label: 'Company Name'
      },
      phone: {
        elementType: 'tel',
        elementConfig: {
          type: 'tel',
          placeholder: 'Phone Number',
        },
        value: '',
        validation: {},
        valid: true,
        touched: false,
        label: 'Contact Phone Number',
        errMessage: 'Invalid phone number'
      },
      comments: {
        elementType: 'textarea',
        elementConfig: {
          type: 'textarea',
          placeholder: 'Comments',
          maxLength: 500,
          rows: 5,
        },
        value: '',
        validation: {},
        valid: true,
        label: 'Comments'
      },
    },
    formValidated: false,
    // server or app errors
    hasError: false,
    submitSuccess: '',
    captchaValid: false,
    captchaRes: '',
    showCapErr: false
  }

  checkValidity(value, rules) {
    let isValid = true;
    if  (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }  
    if(rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    if(rules.maxLength) {
      isValid = value.length <= rules.minLength && isValid;
    }
    return isValid;
  }


  inputChangedHandler = (event, inputId) => {
    let updatedReqForm = {...this.state.reqForm}
    const updatedFormElement = { ...updatedReqForm[inputId]};
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation)
    updatedFormElement.touched = true;
    updatedReqForm[inputId] = updatedFormElement;

    // dynamically update button disable
    let formValidated = true;
    for (let inputId in updatedReqForm) {
      formValidated = updatedReqForm[inputId].valid && formValidated
    }    
    this.setState({
      reqForm: updatedReqForm,
      formValidated: formValidated,
    })
  }

  captchaResChangeHandler = (response) => {
    if (response) {
      this.setState({
        captchaValid: true,
        captchaRes: response,
        showCapErr: false
      });
    }
  };

  formSubmitHandler = (event, reqInfo) => {
    event.preventDefault();
    this.setState({loading: true});
    const formData = {};
    for (let formElementId in this.state.reqForm) {
      formData[formElementId] = this.state.reqForm[formElementId].value
    }
    const captchaRes = this.state.captchaRes;
    const request = {
      msgData: formData,
      captchaRes: captchaRes
    }
    axios.post('/requests/contactrequest',request)
      .then(response => {
        if (response.data.verificationError) {
          this.setState({
            captchaRes: '',
            captchaValid: false,
            formValidated: false,
            showCapErr: true,
            submitSuccess: ''
          })
        }
        if (response.data.error){

          let errors = response.data.error.details
          let reqFormError = {...this.state.reqForm}
          for (let error in errors){
            const invalidField = {...reqFormError[errors[error].context.label]};
            invalidField.valid = false;
            reqFormError[errors[error].context.label] = invalidField
          }

          // dynamically update button disable
          let formValidated = true;
          for (let inputId in reqFormError) {
            formValidated = reqFormError[inputId].valid && formValidated
          }    
          this.setState({
            reqForm: reqFormError,
            formValidated: formValidated,
          })
        } 
        // success//
        if((!response.data.error)&&(!response.data.verificationError)) {
          this.setState({submitSuccess: true})
        }
      })
      .catch(error => {
        this.setState({ 
          loading: false,
          hasError: true,
          submitSuccess: false,
          captchaValid: false,
          captchaRes: ''
        })
      })
  }

  render(){

    //ReCAPTCHA checkbox
    let recaptcha = (
      <div>
      <ReCAPTCHA 
        className={classes.ReCAPTCHA}
        ref={(el) => { this.recaptcha = el; }}
        sitekey="6Le_OOIUAAAAAPG6oH67YCsuFN34CQG3tjddVyJm"
        onChange={this.captchaResChangeHandler}
      />
      {this.state.showCapErr ? <p className={classes.ReqError}>Please check the box above.</p> : null}
      </div> 
    );

    // copy state
    const formElements = [];
    for (let key in this.state.reqForm) {
      formElements.push({
        id: key,
        config: this.state.reqForm[key]
      })
    }
    let form;


    if (this.state.submitSuccess === '') {
      form = (
        <div className={classes.FormContainer}>
        {/* form container */}
          <form className={classes.Form}>
          {formElements.map(formElement => (
            <Input
              key={formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={(event) => this.inputChangedHandler(event, formElement.id)}
              label={formElement.config.label}
              required={formElement.config.validation.required}
              errMessage={formElement.config.errMessage}
            />
          ))}
          </form>
          {recaptcha}
          <Button
            disabled={!this.state.formValidated || !this.state.captchaValid}
            btnType='Submit'
            clicked={(event) => this.formSubmitHandler(event)}
          >SEND</Button>
        </div>
      )
    } else if (this.state.submitSuccess === true) {
      form = (
        <FormResponse
          success={true}
        ></FormResponse>
      )
    }
    else {
      form = (
        <div className={classes.FormContainer}>
          <p className={classes.reqForm}>There was a problem with your request. Please Try Again.</p>
        {/* form container */}
          <form className={classes.Form}>
          {formElements.map(formElement => (
            <Input
              key={formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={(event) => this.inputChangedHandler(event, formElement.id)}
              label={formElement.config.label}
              required={formElement.config.validation.required}
              errMessage={formElement.config.errMessage}
            />
          ))}
          </form>
          {recaptcha}            
        <Button
          disabled={!this.state.formValidated || !this.state.captchaValid}
          btnType='Submit'
          clicked={(event) => this.formSubmitHandler(event)}
        >SEND</Button>
      
        </div>
      )
    }
    return(
      form
    )
  }
}

export default ContactPageForm;