import React from 'react';
import { NavLink } from 'react-router-dom';


import logo from '../../assets/logos/grangertnp.svg'
import classes from './Logo.module.css';

const Logo = (props) => (
    <NavLink to='/' exact onClick={props.clicked}>
    <img src={logo} className={classes.Logo}alt="GRANGER TRACTOR & PARTS INC"></img>
    </NavLink>
);

export default Logo;

