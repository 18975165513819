import React, { Component } from 'react';

import Aux from '../Aux/Aux';


import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import Footer from '../../components/Footer/Footer';
import Drawer from '../../components/Navigation/Dropdown/Drawer/Drawer';
import classes from '../Layout/Layout.module.css';

class Layout extends Component {
  state = {
    showMobileMenu: false
  }

  mobileMenuClosedHandler = () => {
    this.setState({showMobileMenu: false})
  }

  menuToggleHandler = () => {
    this.setState((prevState)=> {
      return {showMobileMenu: !this.state.showMobileMenu}
    });
  }

  render() {
    return (
      <Aux>
        <Toolbar
          menuButtonClicked={this.menuToggleHandler}
          showMobileMenu={this.state.showMobileMenu}
        />
        <Drawer
          open={this.state.showMobileMenu}
          closed={this.mobileMenuClosedHandler}
        />
        <main className={classes.Content}>
          {this.props.children}
        </main>
        <Footer/>
      </Aux>
    )
  }
}

export default Layout;