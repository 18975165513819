import React, { Component } from 'react';


// Handle errors
//TODO redirect to home and show tool tip about partsearching 
const WithErrorHandler = (WrappedComponent) => {
  // for /parts specific error message
  let partsError = '';

  return class extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //console.log(error, info);
  }

  render() {
    const {history}  = this.props;
    history.listen((location, action) => {
      if(this.state.hasError) {
        this.setState({
          hasError: false
        });
      }
    
    });
    return (
      // show specific message for parts page errors
      this.props.match.path === '/parts' ? <h3>{partsError = 'Please enter a part number in the search field'}</h3> : null, 
      // general error message
      this.state.hasError ? <h3>Something went wrong. {partsError}</h3> : <WrappedComponent {...this.props}/>
      // default
    );
  }
}
}
export default WithErrorHandler;
