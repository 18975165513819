import React from 'react';
import classes from './Footer.module.css';

const Footer = (props) => {
  const copyYear = new Date().getFullYear();
  return(
    <div>
      <div className={classes.phantom}/>
        <div className={classes.Footer}>
          <div className={classes.FooterContact}>
            <p>Granger Tractor &amp; Parts, Inc</p>
            <p>459 Medina Rd.</p>
            <p>Medina, OH 44256</p>
            <p>1-330-239-1077</p>  
          </div>  
          <div className={classes.FooterCopyright}>
            <p>&copy; {copyYear} Granger Tractor &amp; Parts, Inc.</p>
            <p>Powered By <a href="https://www.netcomnetwork.com" target="blank">Netcom Services, LLC.</a></p>
          </div>
        </div>
    </div>
  )
};

export default Footer;