import React from 'react';

const location = 'https://www.google.com/maps/embed/v1/place?key='+process.env.REACT_APP_MAP_API_KEY+'&q=Granger+Tractor+%26+Parts'

const Map = () => {
  return(
    <iframe
    width="100%"
    title="placeMap"
    frameBorder="0" style={{border:0}}
    src={location}
    allowFullScreen>
  </iframe>
  )
}

export default Map;