import React, { Component } from 'react';
import { Redirect } from 'react-router';
import ReCAPTCHA from 'react-google-recaptcha';

import axios from '../../config/axiosConfig';

import classes from './ContactForm.module.css';
import WithErrorHandler from '../../components/ErrorBoundaries/WithErrorHandler';

import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import FormResponse from '../../components/UI/FormResponses/FormResponse/FormResponse';
import Aux from '../../hoc/Aux/Aux';

class ContactForm extends Component {
  constructor(props){
    super(props);
    this.goBack = this.goBack.bind(this);
  }
  state = {
    reqForm: {
      name: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Your Name',
          minLength: 3
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        label: 'Name',
        errMessage: "Name must be at least 3 characters long"
      },
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'example@email.com',
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        label: 'Email',
        errMessage: "Invalid email"        
      },
      coName: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Company/Organization',
        },
        value: '',
        validation: {},
        valid: true,
        label: 'Company Name'
      },
      phone: {
        elementType: 'tel',
        elementConfig: {
          type: 'tel',
          placeholder: 'Phone Number',
        },
        value: '',
        validation: {},
        valid: true,
        touched: false,
        label: 'Contact Phone Number',
        errMessage: 'Invalid phone number'
      },
      comments: {
        elementType: 'textarea',
        elementConfig: {
          type: 'textarea',
          placeholder: 'Comments',
          maxLength: 500,
          rows: 5,
        },
        value: '',
        validation: {},
        valid: true,
        label: 'Comments'
      },
    },
    formValidated: false,
    // server or app errors
    hasError: false,
    submitSuccess: '',
    captchaValid: false,
    captchaRes: '',
    showCapErr: false
  }

  checkValidity(value, rules) {
    let isValid = true;
    if  (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }  
    if(rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    if(rules.maxLength) {
      isValid = value.length <= rules.minLength && isValid;
    }
    return isValid;
  }


  inputChangedHandler = (event, inputId) => {
    let updatedReqForm = {...this.state.reqForm}
    const updatedFormElement = { ...updatedReqForm[inputId]};
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation)
    updatedFormElement.touched = true;
    updatedReqForm[inputId] = updatedFormElement;

    // dynamically update button disable
    let formValidated = true;
    for (let inputId in updatedReqForm) {
      formValidated = updatedReqForm[inputId].valid && formValidated
    }    
    this.setState({
      reqForm: updatedReqForm,
      formValidated: formValidated,
    })
  }

  captchaResChangeHandler = (response) => {
    if (response) {
      this.setState({
        captchaValid: true,
        captchaRes: response,
        showCapErr: false
      });
    }
  };

  formSubmitHandler = (event, reqInfo) => {
    event.preventDefault();
    this.setState({loading: true});
    const formData = {};
    for (let formElementId in this.state.reqForm) {
      formData[formElementId] = this.state.reqForm[formElementId].value
    }
    let captchaRes = this.state.captchaRes
    const request = {
      msgData: formData,
      partData: reqInfo,
      captchaRes: captchaRes
    }
    axios.post('/requests/quoterequest',request)
      .then(response => {
        if (response.data.verificationError) {
          this.setState({
            captchaRes: '',
            captchaValid: false,
            formValidated: false,
            showCapErr: true,
            submitSuccess: ''
          })
        }        
        if (response.data.error){
          let errors = response.data.error.details
          let reqFormError = {...this.state.reqForm}
          for (let error in errors){
            const invalidField = {...reqFormError[errors[error].context.label]};
            invalidField.valid = false;
            reqFormError[errors[error].context.label] = invalidField
          }
          // dynamically update button disable
          let formValidated = true;
          for (let inputId in reqFormError) {
            formValidated = reqFormError[inputId].valid && formValidated
          }    
          this.setState({
            reqForm: reqFormError,
            formValidated: formValidated,
          })
        }
        if((!response.data.error)&&(!response.data.verificationError)) {
          this.setState({submitSuccess: true})
        }
      })
      .catch(error => {
        this.setState({ 
          loading: false,
          hasError: true,
          submitSuccess: false,
          captchaValid: false,
          captchaRes: ''          
        })
      })
  }

  goBack(){
    this.props.history.goBack();
  }

  render(){

    if(!this.props.location.state){
      return(
        <Redirect to='/'></Redirect>
      )
    }


    //ReCAPTCHA checkbox
    let recaptcha = (
      <div>
      <ReCAPTCHA 
        className={classes.ReCAPTCHA}
        ref={(el) => { this.recaptcha = el; }}
        sitekey="6Le_OOIUAAAAAPG6oH67YCsuFN34CQG3tjddVyJm"
        onChange={this.captchaResChangeHandler}
      />
      {this.state.showCapErr ? <p className={classes.ReqError}>Please check the box above.</p> : null}
      </div> 
    );

    /* reqInfo object keys 
      condName:
      desc:
      id:
      manufName:
      partNum:
      quan:
    */ 
    let reqInfo = {};
    reqInfo = this.props.location.state;

    // copy state
    const formElements = [];
    for (let key in this.state.reqForm) {
      formElements.push({
        id: key,
        config: this.state.reqForm[key]
      })
    }

    let form;

    if (this.state.submitSuccess === '') {
    form = (
      <div className={classes.FormContainer}>
        <h2>Reqest a Quote for {reqInfo.manufName} Part #{reqInfo.partNum}</h2>
        {/* form container */}
        <div>
          <form className={classes.Form}>
          {formElements.map(formElement => (
            <Input
              key={formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={(event) => this.inputChangedHandler(event, formElement.id)}
              label={formElement.config.label}
              required={formElement.config.validation.required}
              errMessage={formElement.config.errMessage}
            />
          ))}
          </form>
        </div>
        <div className={classes.CapContainer}>
        {recaptcha}
        </div>
        <Button
          disabled={!this.state.formValidated}
          btnType='Submit'
          clicked={(event) => this.formSubmitHandler(event, reqInfo)}
        >SEND</Button>

      </div>
    )
    } else if (this.state.submitSuccess === true) {
      form = (
        <div className={classes.FormContainer}>
          <FormResponse className={classes.FormResponse}
            success={true}
          ></FormResponse>
        
        <div className={classes.Call}>
          <Button
            btnType='GetQuote'
            clicked={this.goBack}
          >BACK TO PARTS</Button>
        </div>
        </div>
      )
    }
    else {
      form = (
        <div className={classes.FormContainer}>
          <h2>Reqest a Quote for {reqInfo.manufName} Part #{reqInfo.partNum}</h2>
          {/* form container */}
          <div>
          <p className={classes.ReqError}>There was a problem with your request. Please Try Again.</p>
            <form className={classes.Form}>
            {formElements.map(formElement => (
              <Input
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) => this.inputChangedHandler(event, formElement.id)}
                label={formElement.config.label}
                required={formElement.config.validation.required}
                errMessage={formElement.config.errMessage}
              />
            ))}
            </form>
            <div className={classes.CapContainer}>            
            {recaptcha}
            </div>
          </div>
          <div className={classes.BackButtonContainer}>
            <Button
              disabled={!this.state.formValidated}
              btnType='Submit'
              clicked={(event) => this.formSubmitHandler(event, reqInfo)}
            >SEND</Button>
          </div>
        </div>
      )
    }

    return(
      <Aux>
        {form}
        <div className={classes.Call}>
        <h3>CANT WAIT? CALL NOW:</h3>
        <h3>1-330-239-1077</h3>
      </div>
      </Aux>
    )
  }
}

export default WithErrorHandler(ContactForm);

