import React, { Component } from 'react';

class Machines extends Component {
  render() {
    return (
      <div>
        MACHINES PLACEHOLDER
      </div>
    );
  };
};

export default Machines;


