import React from 'react'

import classes from './Part.module.css';
import Button from '../UI/Button/Button';
import { Link } from 'react-router-dom';

const Part = (props) => {
  return (
    
    <div className={classes.Part}>
      <div className={classes.Info}>
        <small className={'secondaryText'}>Part #: </small>
        <strong>{props.partNum}</strong>
      </div>
      <div className={classes.Info}>
        <small className={'secondaryText'}>Description: </small>
        {props.desc}
      </div>
      <div className={classes.Info}>
        <small className={'secondaryText'}>Manufacturer: </small>
        {props.manufName}
      </div>
      <div className={classes.Info}>
        <small className={'secondaryText'}>Condition: </small>
        {props.condName}
      </div>
      <div className={classes.Info}>
        <small className={'secondaryText'}>Quantity: </small>
        {props.quan}
      </div>
      <div className={classes.Info}>
        <Link to={{
          pathname: '/contactform',
          state: {
            ...props
          }
        }}>
          <Button
            btnType="GetQuote"
          >GET QUOTE</Button>
        </Link>
      </div>
    </div>
  )
}

export default Part;