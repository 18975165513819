import React, {Component} from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Layout from './hoc/Layout/Layout';
import Home from './containers/Home/Home';
import Parts from './containers/Parts/Parts';
import Machines from './containers/Machines/Machines';
import Contact from './containers/Contact/Contact';
import ContactForm from './containers/ContactForm/ContactForm';
import NotFound from './containers/NotFound/NotFound';
import classes from './App.module.css';

// import pages from components/containers
class App extends Component {
  render() {
    return (
      <div className={classes.LayoutWrapper}>
      <Layout>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/machines" component={Machines} />
          <Route path="/contact" component={Contact} />
          <Route path="/parts" component={Parts} />
          <Route path="/contactform" component={ContactForm} />
          <Route component={NotFound}/>
        </Switch>
      </Layout>
      </div>
    );
  } 
}


export default withRouter(App);
