import React from 'react';
import NumberFormat from 'react-number-format';

import Aux from '../../../hoc/Aux/Aux';

import classes from './Input.module.css';

const Input = (props) => {
  let inputElement = null;
  let showError = false;
  const inputClasses = [classes.InputElement];
  

  if (props.invalid && props.shouldValidate && props.touched) {
    inputClasses.push(classes.Invalid);
    showError = true;
  };

  switch (props.elementType) {
    case ('search'):
      inputClasses.push(classes.Search)
      inputElement = <input
        className={inputClasses.join(' ')}
        {...props.elementConfig}
        aria-label={"Part Search"}
        aria-required={true}
        value={props.value}
        onChange={props.changed}
      />
    break;
    case ('input'):
      inputElement = <input
        className={inputClasses.join(' ')}
        {...props.elementConfig}
        value={props.value}
        onChange={props.changed}
        />   
    break;
    case ('tel'):
      inputElement = <NumberFormat 
        format="###-###-####" mask="_"
        className={inputClasses.join(' ')}
        {...props.elementConfig}
        value={props.value}
        onChange={props.changed}
    />      
    break;    
    case ('textarea'): 
      inputClasses.push(classes.Textarea)
      inputElement = <textarea 
        className={inputClasses.join(' ')}
        {...props.elementConfig} 
        value={props.value}
        onChange={props.changed}
        />
    break;      
    default:
        inputElement = <input 
          className={inputClasses.join(' ')}
          {...props.elementConfig} 
          value={props.value}
          onChange={props.changed}/>
  };
  return (
    <Aux>
      <label className={classes.Label}>{props.label}
      {props.required ? ' (Required)' : null}
      </label>
      {inputElement}
      {showError ? <p className={classes.Error}>{props.errMessage}</p> : null}
    </Aux>
  );
};

export default Input;
