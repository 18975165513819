import React, { Component } from 'react'

import axios from '../../config/axiosConfig';

import Part from '../../components/Part/Part';
import Spinner from '../../components/UI/Spinner/Spinner';

import classes from './Parts.module.css';
import WithErrorHandler from '../../components/ErrorBoundaries/WithErrorHandler';


class Parts extends Component {
  // get loading value from searchbar component
  state ={
    loading: this.props.location.state.loading,
    hasError: false,
  };
  

  // parts api requests
  requestHandler = () => {
    axios.get('/parts/search/'+ this.props.location.state.query)
    .then(response => {
      const resultCount = response.data.count
      const partResults = [];
      for (let key in response.data.parts) {
        partResults.push({
          ...response.data.parts[key],
          id: key
        });
      }
      this.setState({
        count: resultCount,
        parts: partResults,
        loading: false
      });
    })
    .catch(err => {
      this.setState({
        loading: false,
        hasError: true
      });
    });
  }
  
  // handle searches on initial page load
  componentDidMount(){
    this.requestHandler();
  }

  // use componentDidUpdate to ensure parts page update after each search
  // https://reactjs.org/docs/react-component.html#componentdidupdate
  componentDidUpdate(prevProps) {
    // compare previous props and current(created and received by the search onSubmit event) props to determine if api should be called
    // !prevState is used to make the first api call
    if((this.props.location.key !== prevProps.location.key)) {
      this.requestHandler()
    }
  };



  render() {
    // show loading animation if loading === true
    let parts = <Spinner/>;
    if(!this.state.loading) { 
      if(!this.state.hasError) {
        parts = (
          this.state.parts.map(part => (
            <Part 
              id={part.partId}
              partNum={part.partNumber}
              condName={part.condName}
              manufName={part.manufName}
              quan={part.quanAval}
              desc={part.descript}
              key={part.partId}
            />
          ))
        )
      }
    }
    
    let resultHeader = ''
    const count = this.state.count
    if(count > 0) {
      resultHeader = count+' PARTS MATCH YOUR SEARCH'
    } else {
      resultHeader = 'NO PARTS FOUND'
    }

    return (
      
      <div>
        {this.state.loading || this.state.hasError ? null : <h2 className={classes.Header}>{resultHeader}</h2>}
        {parts}
      </div>
    )
  }
}

export default WithErrorHandler(Parts);



