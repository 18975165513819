import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';
// search bar
import SearchBar from '../../UI/SearchBar/SearchBar';
// const NavigationItems = (props) => (
//   <ul className={classes.NavigationItems}>
//     <NavigationItem link="/" active clicked={props.clicked}>
//       Home
//     </NavigationItem>
//     <NavigationItem link="/parts" clicked={props.clicked}>Part Search</NavigationItem>
//     <Input/>
//     <NavigationItem link="/machines" clicked={props.clicked}>Machines</NavigationItem>
//     <NavigationItem link="/contact" clicked={props.clicked}>Contact Us</NavigationItem>
//   </ul>
// );

const NavigationItems = (props) => (
      <ul className={classes.NavigationItems}>
        <li onSubmit={props.clicked} className={classes.Search}><SearchBar/></li>
        <div className={classes.MobileOnlyDiv}></div>
        <NavigationItem link="/" clicked={props.clicked}>
          Home
        </NavigationItem>
        <div className={classes.MobileOnlyDiv}></div>
        {/*<NavigationItem link="/parts" clicked={this.props.clicked}>
          Part Search
    </NavigationItem>
        <NavigationItem link="/machines" clicked={props.clicked}>
          Machines
  </NavigationItem>*/}
        <div className={classes.MobileOnlyDiv}></div>
        <NavigationItem link="/contact" clicked={props.clicked}>
          Contact
        </NavigationItem>
      </ul>
);

export default NavigationItems;