import React from 'react';

import classes from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
//dropdown stuff
import NavigationItems from '../NavigationItems/NavigationItems';
import MenuButton from '../Dropdown/MenuButton';

const Toolbar = (props) => (
  <header className={classes.Toolbar}>
    <div className={classes.Logo}>
      <Logo />
    </div>
    <nav className={classes.DesktopOnly}>
      <NavigationItems />
    </nav>
    <MenuButton active={props.showMobileMenu} clicked={props.menuButtonClicked}/>
  </header>
);


export default Toolbar;
