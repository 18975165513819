import React, { Component } from 'react';
import classes from './MenuButton.module.css';


class MenuButton extends Component {
  state = {
    active: this.props.active
  }

  clickHandler = () => {
    this.setState({
      active: !this.state.active
    })
  }


  render(){
  return (
  <div className={this.props.active ? classes.MenuButton + ' ' + classes.MenuButtonActive : classes.MenuButton} onClick={() => {this.props.clicked(); this.clickHandler()}}>
    <div></div>
  </div>
  )
  }
};


export default MenuButton;
